import React from "react";
import styles from "./index.module.scss";
import { createFromIconfontCN } from "@ant-design/icons";
import { SITE_SOURCE, PC_HOST } from "../../utils/consts";
import Mark from "../../components/mark";
import { Button, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import OCLImg from "../../assets/ocl-callback.png";
import OEImg from "../../assets/oe-callback.png";
import { useHistory } from "react-router-dom";
import { ClickCopyURL } from "@/utils/sensors.js";

export default () => {
    const history = useHistory();
    const inviteLink = PC_HOST + "/callback";
    let _params = new URL(location.href).searchParams;
    let token = _params.get("t") || localStorage.getItem("access_token");
    // const targetLink = `http://localhost:8000/service/detail`;
    const targetLink = `${PC_HOST}/callback`;

    return (
        <div
            className={styles.viewpager}
            style={SITE_SOURCE === "OCL" ? { backgroundImage: `url(${OCLImg})` } : { backgroundImage: `url(${OEImg})` }}
        >
            <div className={styles.title}>
                {SITE_SOURCE === "OCL" ? "Otis ONE" : "OTIS CONNECT"}
                <Mark fontSize={"15px"} top={"-20px"} />
            </div>
            {SITE_SOURCE === "OCL" ? (
                <>
                    <div className={styles.bg1} /> <div className={styles.bg2} />
                </>
            ) : (
                <div className={styles.oebg} />
            )}
            <p>为保证您的使用体验，请登录PC端访问:</p>
            <p>{inviteLink}</p>
            <Button
                color="primary"
                onClick={() => {
                    Toast.show({
                        icon: "success",
                        content: "复制成功！",
                    });
                    copy(targetLink);
                    ClickCopyURL({
                        ...history.location?.state,
                        webURL: targetLink,
                    });
                }}
                className={styles.btn_copy}
            >
                点击复制网址
            </Button>
        </div>
    );
};
