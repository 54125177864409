import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import styles from "./index.module.scss";
import { Button, Steps, Toast, Popover } from "antd-mobile";
import BizIcon from "../../components/bizIcon";
import axios from "axios";
import { HOST, HOST_OES, PC_HOST, SITE_SOURCE } from "../../utils/consts";
import { useHistory } from "react-router-dom";
import moment from "moment";
import OCLImg from "../../assets/ocl-callback.png";
import OEImg from "../../assets/oe-callback.png";
import Empty from "./components/Empty";
import { Clickphone_callback, viewAllcallback } from "@/utils/sensors.js";

const { Step } = Steps;

const fixApi = {
    callback: "/api/oes/h5/callback",
    getFloor: "/api/unit/unitRunningDataByUnitNumber",
};

//steps对应文案
const progress = {
    1: "技师正火速赶来",
    2: "技师正火速赶来",
    3: "技师已到达现场，正在修复",
    4: "技师已到达现场，正在修复",
    5: "维修技师已完成修复",
};

export default () => {
    const history = useHistory();
    const _token = localStorage.getItem("access_token");
    //获取URL参数
    let _params = new URL(location.href).searchParams;
    //判断icon默认前缀
    const site_source = SITE_SOURCE === "OCL" ? "ocl-" : "oe-";
    //URL接收的参数
    const [params, setParams] = useState(null);
    //召修数据
    const [callbackData, setCallbackData] = useState(null);
    const [callbackLoading, setCallbackLoading] = useState(true);
    //楼层
    const [unitInfo, setUnitInfo] = useState(null);
    //用于stepsIcon的展示
    const [stepsIconIndex, setStepsIconIndex] = useState({
        0: "failure",
        1: "dispatch",
        2: "callback",
        3: "fixed",
    });
    //判断故障类型 1代表底坑进水
    const [type, setType] = useState(null);
    //召修的ID
    const [id, setId] = useState(null);
    //默认故障
    const [isFix, setIsFix] = useState(false);

    //获取召修信息
    const fetchCallback = async () => {
        // const res = await axios.get(`/rest-oes-api/api/oes/h5/callback`, { params });
        const res = await axios.get(`${HOST_OES}/api/oes/h5/callback`, { params });
        setCallbackData(res.data);
        setCallbackLoading(false);
        Toast.clear();
    };
    //获取楼层
    const fetchFloor = async () => {
        const res = await axios.post(`${HOST}/api/h5/unitDetail`, {
            unitNumber: callbackData?.unitNo,
            entity: SITE_SOURCE,
        });
        setUnitInfo(res.data);
    };

    //查看全部召修记录
    const linkJump = () => {
        viewAllcallback({
            callbackID: callbackData?.callBackNo,
            mechanicName: callbackData?.mechanic,
            unitNumber: callbackData?.unitNo,
            buildingName: callbackData?.buildingName,
            callbackStatus: callbackData?.callbackStatus,
        });
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(navigator.userAgent)) {
            //当前设备为移动端（H5）跳转至引导页
            history.push({
                pathname: `/viewpager`,
                search: location.search,
                state: {
                    callbackID: callbackData?.callBackNo,
                    mechanicName: callbackData?.mechanic,
                    unitNumber: callbackData?.unitNo,
                    buildingName: callbackData?.buildingName,
                    callbackStatus: callbackData?.callbackStatus,
                },
            });
        } else {
            //当前设备为桌面显示器（PC） 跳转至PC服务详情
            window.location.replace(`${PC_HOST}/callback?t=${localStorage.getItem("access_token")}`);
        }
    };

    const getEmptyText = () => {
        if (callbackData?.callbackStatus === -10) {
            return "设备不存在";
        } else if (callbackData?.callbackStatus === -2) {
            return "该召修单已取消";
        } else {
            return "未查询到该召修单";
        }
    };

    useEffect(() => {
        if (callbackData?.progress?.length > 4) {
            //说明有困人，添加一条信息
            setStepsIconIndex({
                0: "failure",
                1: "dispatch",
                2: "callback",
                3: "callback",
                4: "fixed",
            });
        }
        if (callbackData) {
            fetchFloor();
            /* -1, 7, 8代表已修复 */
            if (
                callbackData?.callbackStatus === -1 ||
                callbackData?.callbackStatus === 7 ||
                callbackData?.callbackStatus === 8
            ) {
                setIsFix(true);
            }
        }
    }, [callbackData]);

    useEffect(() => {
        setParams({
            callbackId: _params.get("id"),
        });
        setType(_params.get("type"));
        setId(_params.get("id"));
        // 删除URL中的token
        // window.history.replaceState(null, "", `/fix?type=${_params.get("type")}&id=${_params.get("id")}`);
    }, [location]);

    useEffect(() => {
        if (params) {
            Toast.show({
                icon: "loading",
                content: "加载中…",
            });
            fetchCallback();
        }
    }, [params]);

    return (
        <>
            {callbackData && callbackData?.callbackStatus != -2 && callbackData?.callbackStatus != -10 ? (
                <div
                    className={styles.fix}
                    style={
                        SITE_SOURCE === "OCL"
                            ? { backgroundImage: `url(${OCLImg})` }
                            : { backgroundImage: `url(${OEImg})` }
                    }
                >
                    <div className={styles.panel}>
                        {isFix || callbackData?.callbackStatus === -2 ? (
                            <BizIcon type="fixed" className={styles["main-icon"]} />
                        ) : (
                            <>
                                {type == 1 ? (
                                    <BizIcon type="water" className={styles["main-icon"]} />
                                ) : (
                                    <BizIcon type="fault" className={styles["main-icon"]} />
                                )}
                            </>
                        )}
                        <span className={styles.title}>
                            {callbackData?.callbackStatus === -2 ? (
                                "召修单已取消"
                            ) : (
                                <>{isFix ? <>故障已修复</> : <>{type == 1 ? "底坑进水" : "故障召修"}</>}</>
                            )}
                        </span>
                        <span>{callbackData?.aliasName || "-"}</span>
                        <span>{callbackData?.buildingName || "-"}</span>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.user}>
                            <div className={styles.avatar} />
                            <div className={styles.info}>
                                <div className={styles.basic}>
                                    <Popover content={callbackData?.mechanic} placement="top">
                                        <span className={styles.name}>{callbackData?.mechanic}</span>
                                    </Popover>
                                    <BizIcon type="temperature" className={styles["health-icon"]} />
                                    <span className={styles.health}>体温正常</span>
                                </div>
                                <div className={styles.status}>{progress[callbackData?.progressKey]}</div>
                            </div>
                            <a
                                href={"tel:" + callbackData?.mechanicMobile}
                                onClick={() => {
                                    Clickphone_callback({
                                        callbackID: callbackData?.callBackNo,
                                        mechanicName: callbackData?.mechanic,
                                        unitNumber: callbackData?.unitNo,
                                        buildingName: callbackData?.buildingName,
                                        callbackStatus: callbackData?.callbackStatus,
                                    });
                                }}
                            >
                                <BizIcon type={site_source + "mobile"} className={styles["call"]} />
                            </a>
                        </div>

                        {/* 故障已修复时展示诊断结果 */}
                        {isFix && <div className={styles.result}>诊断结果：{callbackData?.causeSymptomAction}</div>}
                    </div>

                    <div
                        className={styles.card}
                        style={{ backgroundColor: SITE_SOURCE === "OCL" ? " #f65275" : "#00a0e9", borderRadius: "6px" }}
                    >
                        <div className={styles.contract}>
                            <span>维保合同号：{callbackData?.contractNo}</span>
                        </div>
                        <div className={styles.steps}>
                            <Steps direction="vertical" current={callbackData?.progressKey - 1}>
                                {callbackData?.progress?.map((item, index) => {
                                    return (
                                        <Step
                                            key={index}
                                            title={<StepItem title={item.key} date={item.date} time={item.time} />}
                                            icon={
                                                <BizIcon
                                                    type={
                                                        item.date === null
                                                            ? stepsIconIndex[index] + "-disable"
                                                            : site_source + stepsIconIndex[index]
                                                    }
                                                    className={styles["steps-icon"]}
                                                />
                                            }
                                        />
                                    );
                                })}
                            </Steps>
                        </div>
                    </div>
                    <div
                        className={styles.card}
                        onClick={async () => {
                            const res = await axios.get(
                                `${HOST}/api/unit/validUnitViewPermission?unitNumber=${unitInfo?.unitNumber}`
                            );

                            if (res.data === true) {
                                if (unitInfo?.unitType == "Elevator") {
                                    // history.push({
                                    //     pathname: `/unit`,
                                    //     search: `unitNumber=${callbackData?.unitNo}&system=h5&unitType=elevator`,
                                    // });
                                    window.location.href = `${PC_HOST}/public/units/elevator/${unitInfo?.id}/${unitInfo?.unitNumber}?t=${_token}`;
                                } else {
                                    // history.push({
                                    //     pathname: `/unit`,
                                    //     search: `unitNumber=${callbackData?.unitNo}&system=h5&unitType=escalator`,
                                    // });
                                    window.location.href = `${PC_HOST}/public/units/escalator/${unitInfo?.id}/${unitInfo?.unitNumber}?t=${_token}`;
                                }
                            } else {
                                Toast.show({
                                    content: "暂无访问权限",
                                    duration: 5000,
                                });
                            }
                        }}
                    >
                        <div className={styles.unit}>
                            <div className={styles["id-panel"]}>
                                <div className={styles.id}>{callbackData?.unitNo}</div>
                                <div>故障设备ID</div>
                            </div>
                            <div className={styles.line1}></div>
                            <div className={styles.arrow}></div>
                            <div className={styles.line2}></div>

                            <div className={styles.detail}>
                                <UnitDetailItem label="设备类型">
                                    {callbackData?.unitType === "Elevator" ? "直梯" : "扶梯"}
                                </UnitDetailItem>
                                {callbackData?.unitType === "Elevator" && (
                                    <UnitDetailItem label="当前楼层" required>
                                        {unitInfo?.dispFloor || "-"}
                                    </UnitDetailItem>
                                )}
                                <UnitDetailItem label="梯龄">
                                    {unitInfo?.unitAge === null ? (
                                        "-"
                                    ) : (
                                        <>{unitInfo?.unitAge < 1 ? "<1" : unitInfo?.unitAge}年</>
                                    )}
                                </UnitDetailItem>
                            </div>
                        </div>
                    </div>
                    {callbackData?.unitType === "Elevator" ? (
                        <span className={styles.des}>
                            *当前楼层数值为系统最后接收到的楼层信息，可能与设备实际所在楼层有所偏差
                        </span>
                    ) : (
                        <></>
                    )}

                    <Button type="button" color="primary" onClick={linkJump}>
                        查看全部召修记录
                    </Button>
                </div>
            ) : (
                <Empty
                    loading={callbackLoading}
                    // text={callbackData?.callbackStatus == -2 ? "该召修单已取消" : "未查询到该召修单"}
                    text={getEmptyText()}
                />
            )}
        </>
    );
};

function StepItem({ title, date = "", time = "" }) {
    return (
        <div className={styles["step-item"]}>
            <span className={`${styles.title} ${date === null ? styles.disabled : ""}`}>{title}</span>
            <span className={styles.date}>{date ? moment(date).format("YYYY-MM-DD") : ""}</span>
            <span>{time ? moment(time).format("HH:mm") : ""}</span>
        </div>
    );
}

function UnitDetailItem({ label, required, children }) {
    return (
        <div className={styles["detail-item"]}>
            <div className={styles.label}>
                {label}
                {required ? <span className={styles.required}>*</span> : ""}:
            </div>
            <span>{children}</span>
        </div>
    );
}
