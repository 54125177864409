import React from "react";
import styles from "./index.module.scss";
import { SITE_SOURCE, PC_HOST } from "../../utils/consts";
import { Button } from "antd-mobile";
import OCLImg from "../../assets/ocl_guide.png";
import OEImg from "../../assets/oe_guide.png";
import { background } from "@/utils/background";

export default () => {
    return (
        <div className={styles.guide} style={background()}>
            <img width={"100%"} src={SITE_SOURCE === "OCL" ? OCLImg : OEImg} alt="" />
            <div className={styles.btn_body}>
                <Button
                    color="primary"
                    className={styles.btn}
                    onClick={() => {
                        window.location.href = PC_HOST;
                    }}
                >
                    点击跳转到登录页面
                </Button>
            </div>
        </div>
    );
};
