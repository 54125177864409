import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "@/routes";
import "./app.css";
import { SITE_SOURCE, REACT_APP_ENV } from "./utils/consts";
import jwt_decode from "jwt-decode";
import "./iconfont.js";
import { Outside } from "./utils/sensors";

export default (props) => {
    // document.title = SITE_SOURCE === "OCL" ? "Otis" : "Otis CONNECT";
    let _params = new URL(location.href).searchParams;
    let _token = _params.get("t");
    if (_token) {
        localStorage.setItem("access_token", _token);
    }

    if (SITE_SOURCE === "OCL") {
        document.documentElement.style.setProperty("--adm-color-primary", "#f65275");
    } else {
        document.documentElement.style.setProperty("--adm-color-primary", "#1890ff");
    }
    const sensors_env = {
        dev: { project: "default" },
        eview: { project: "default" },
        eviewqa: { project: "default" },
        test: { project: "default" },
        production: { project: "production" },
    };
    const sensors = window["sensorsDataAnalytic201505"];
    const sensors_project = sensors_env[REACT_APP_ENV].project;
    const sensors_token = "99e2b417ae69f06f6608f97dccd42ad0872b03722ae28ed7644de5baad232543";
    sensors.init({
        server_url: `https://sensorsdata.azurefd.net/sa?token=${sensors_token}&project=${sensors_project}`,
        is_track_single_page: false, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
        use_client_time: true,
        send_type: "beacon",
        show_log: false,
        heatmap: {
            //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            clickmap: "default",
            scroll_notice_map: "default",
            get_vtrack_config: true,
        },
    });
    let system = _params.get("system") || "CP";
    system = system.toLocaleUpperCase();
    let userid = _params.get("userid");
    sensors.quick("isReady", function () {
        sensors.registerPage({
            otis_env: REACT_APP_ENV,
            otis_source: SITE_SOURCE,
            otis_os: "h5",
            otis_system: "CP",
        });
        sensors.quick("autoTrackSinglePage");
        sensors.use("PageLeave");
        try {
            const decode = jwt_decode(localStorage.getItem("access_token"));
            if (decode && decode.userInfo) {
                let userinfo = decode.userInfo;
                let id = userinfo.id;
                sensors.login(id);
                sensors.setProfile({
                    userId: userinfo.id,
                    name: userinfo.name,
                    email: userinfo.email,
                    roles: userinfo.roles,
                    source: userinfo.source,
                    status: userinfo.status,
                    system,
                });
            } else if (userid) {
                sensors.login(userid);
                sensors.setProfile({
                    userId: userid,
                });
            } else {
                sensors.logout(true);
            }
        } catch (err) {
            console.warn(err);
        }
        if (_params.get("_p") && _params.get("_t")) Outside({ _p: _params.get("_p"), _t: _params.get("_t") });
    });
    return (
        <div className="app">
            <Router>
                <Switch>
                    {routes.map((r, index) => {
                        return (
                            <Route
                                key={index}
                                path={r.route}
                                exact={r.exact}
                                // onEnter={setTitle(r.title)}  已废弃
                                render={(props) => {
                                    document.title =
                                        r.title + " - " + (SITE_SOURCE === "OCL" ? "Otis ONE®" : "OTIS CONNECT");
                                    return <r.component />;
                                }}
                            ></Route>
                        );
                    })}
                </Switch>
            </Router>
        </div>
    );
};
