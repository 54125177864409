import { Toast } from "antd-mobile";
import axios from "axios";
import { SITE_SOURCE, PC_HOST, HOST, HOST_OES } from "./consts";
import jwt_decode from "jwt-decode";

const getToken = () => {
    let _params = new URL(location.href).searchParams;
    const t = _params?.get("t");
    if (t) {
        localStorage.setItem("access_token", t);
    }
    return t ?? localStorage.getItem("access_token");
};

axios.defaults.baseURL = "/";
axios.interceptors.request.use(
    (config) => {
        if (getToken()) {
            const decode = jwt_decode(getToken());
            config.headers["x-ref"] = decode?.userInfo?.id;
        } else {
            config.headers["x-ref"] = "";
        }
        config.headers["internal-entity"] = SITE_SOURCE;
        // const token = getToken();
        // config.headers.Authorization = `Bearer ${token}`;
        if (config.url.search("cxServer") === -1) {
            //非CX的接口添加token
            const token = getToken();
            config.headers.Authorization = `Bearer ${token}`;
        }
        // if (config.url.indexOf("/rest-") === 0) {
        // 如果包含/rest-oes-api替换为HOST_OES,  包含/rest-cp-api替换为HOST
        //     config.url = config.url.replaceAll("/rest-oes-api", HOST_OES).replaceAll("/rest-cp-api", HOST);
        // }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        console.log(error);
        if (error.response.status === 401) {
            Toast.show({
                icon: "fail",
                content: "登录状态已失效，请重新登录！",
                duration: 2000,
            });
            localStorage.removeItem("access_token");
            //401时到PC端登录
            // if (process?.env?.REACT_APP_DEBUG?.toString() !== "true") {
            // }
            setTimeout(() => {
                // window.location.replace(
                //     `${PC_HOST}/user/login?f=mobile&type=${_params.get("type")}&id=${_params.get("id")}`
                // );
                // window.location.href = `${PC_HOST}/user/login?targetUrl=${encodeURIComponent(location.href)}`;
                // window.location.href = `http://localhost:8000/user/login?targetUrl=${encodeURIComponent(location.href)}`;
            }, 1500);
        } else {
            Toast.show({
                icon: "fail",
                content: "网络异常，请稍后再试！",
                duration: 3000,
            });
        }

        return Promise.reject(error.data);
    }
);
