import React, { useEffect, useState } from "react";

import * as echarts from "echarts";

const DayChart = (props) => {
    let myChart;
    const { DayData, unitType } = props;
    const xyAxis = {
        xAxis: [
            {
                show: false,
                type: "category",
                data: DayData?.fakexAxis,
                //隐藏x轴刻度线
                axisTick: {
                    show: false,
                },
                axisPointer: {
                    show: true,
                    type: "line",
                    snap: true,
                    lineStyle: {
                        color: "#F8B942",
                    },
                    label: {
                        show: false,
                    },
                },
            },
            {
                name: "时间",
                nameGap: 8,
                // minInterval: 9,
                nameTextStyle: {
                    lineHeight: 26,
                    color: "#ccc",
                    verticalAlign: "top",
                },
                //隐藏鼠标hover坐标线
                axisPointer: {
                    type: "none",
                    snap: false,
                },
                type: "category",
                boundaryGap: false,
                position: "bottom",
                data: DayData?.realxAxis,
                axisLabel: {
                    color: "#000",
                    fontSize: 11,
                    interval: 0,
                },
                //隐藏x轴刻度线
                axisTick: {
                    show: false,
                },
                axisLine: {
                    symbol: ["none", "triangle"],
                    symbolSize: [5, 10],
                    lineStyle: {
                        width: 1,
                        color: "#cfcfcd",
                    },
                },
                triggerEvent: true,
            },
        ],
        grid: {
            left: "15%",
        },
        yAxis: [
            {
                type: "value",
                name: unitType == "Elevator" ? "次数" : "人次",
                offset: 1,
                boundaryGap: [0, "5%"],
                axisPointer: {
                    show: true,
                    type: "line",
                    snap: true,
                    label: {
                        show: false,
                    },
                    lineStyle: {
                        color: "#F8B942",
                    },
                },
                nameTextStyle: {
                    align: "right",
                    color: "#ccc",
                },
                // minInterval: 20,
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                    },
                },
                axisLine: {
                    show: true,
                    symbol: ["none", "triangle"],
                    symbolSize: [5, 10],
                    lineStyle: {
                        width: 1,
                        color: "#cfcfcd",
                    },
                },
                axisLabel: {
                    color: "#000",
                    formatter: (value) => {
                        if (value != 0) {
                            return value;
                        }
                    },
                },
            },
        ],
    };
    const [dayChart, setDayChart] = useState(null);
    const [peakActive, setPeakActive] = useState({
        isMorning: false,
        isNoon: false,
        isEvening: false,
    });
    useEffect(() => {
        if (!myChart) {
            myChart = echarts.init(document.getElementById("chart-day"));
            setDayChart(myChart);
            myChart.showLoading({
                text: "正在加载...",
            });
        }

        if (DayData?.runCounts) {
            if (DayData.runCounts.length > 0) {
                let { Morning, Evening, Noon } = DayData?.PeakTime;

                let _peakPoints = [],
                    _peakRange = [];
                for (let i in DayData?.PeakTime) {
                    if (DayData?.PeakTime[i] && DayData?.PeakTime[i].value > 300) {
                        _peakPoints.push({
                            xAxis: DayData?.PeakTime[i].start,
                            yAxis: DayData?.PeakTime[i].value,
                            symbol: "circle",
                            symbolSize: 12,
                        });
                        _peakRange.push([
                            {
                                xAxis: DayData?.PeakTime[i].start,
                            },
                            {
                                xAxis: DayData?.PeakTime[i].start + 1,
                            },
                        ]);
                    }
                }

                let option = {
                    title: {
                        show: false,
                    },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: "cross",
                        position: (point, params, dom, rect, size) => {
                            var obj = { top: 60 };
                            obj[["left", "right"][+(point[0] < size.viewSize[0] / 2)]] = 5;
                            return obj;
                        },
                        borderWidth: 0,
                        formatter: (params) => {
                            const { componentType, name, data } = params[0];
                            if (componentType === "series") {
                                let from = parseInt(name) - 1,
                                    to = parseInt(name) + 1;
                                return `时段：${from}:00~${to}:00<br>客流量：<strong>${data}</strong>人次`;
                            }
                        },
                    },
                    series: [
                        {
                            type: "line",
                            name: "时段",
                            symbol: "circle",
                            symbolSize: 8,
                            showAllSymbol: false, //小圆点间隔显示
                            itemStyle: {
                                color: "#00aca2",
                            },
                            smooth: false,
                            lineStyle: {
                                width: 4,
                                shadowColor: "#bbb",
                                shadowBlur: 10,
                                shadowOffsetX: "3",
                                shadowOffsetY: "3",
                                opacity: 1,
                            },
                            data: DayData?.runCounts,
                            //图表标注：高峰点
                            markPoint: {
                                data: _peakPoints,
                                itemStyle: {
                                    color: "#F8B942",
                                },
                                label: {
                                    show: false,
                                },
                            },
                        },
                        {
                            type: "line",
                            xAxisIndex: 1,
                            markArea: {
                                slient: true,
                                itemStyle: {
                                    color: "rgba(248,185,66,.1)",
                                },
                                emphasis: {
                                    label: {
                                        show: false,
                                    },
                                    itemStyle: {
                                        color: "rgba(248,185,66,.5)",
                                    },
                                },
                                data: _peakRange,
                            },
                        },
                    ],
                    ...xyAxis,
                };
                myChart.setOption(option);
                myChart.on("mouseover", (params) => {
                    const { componentType, data } = params;
                    if (componentType === "markArea") {
                        let realstart = data.xAxis * 2;
                        if (realstart < 10) {
                            setPeakActive({
                                isMorning: true,
                                isNoon: false,
                                isEvening: false,
                            });
                        } else if (realstart >= 10 && realstart < 16) {
                            setPeakActive({
                                isMorning: false,
                                isNoon: true,
                                isEvening: false,
                            });
                        } else {
                            setPeakActive({
                                isMorning: false,
                                isNoon: false,
                                isEvening: true,
                            });
                        }
                    }
                });
                myChart.on("mouseout", () => {
                    setPeakActive({
                        isMorning: false,
                        isNoon: false,
                        isEvening: false,
                    });
                });
            }
        } else {
            let option = {
                title: {
                    show: true,
                    text: "暂无数据",
                    x: "center",
                    y: "center",
                    textStyle: {
                        color: "#65ABE7",
                        fontWeight: "normal",
                        fontSize: 14,
                    },
                },
                series: [
                    {
                        type: "line",
                        data: [],
                    },
                ],
                ...xyAxis,
            };
            myChart.setOption(option);
        }
        myChart.hideLoading();
    }, [DayData]);

    return (
        <div
            id="chart-day"
            style={{
                width: "auto",
                height: "220px",
                background: "#fff",
            }}
        ></div>
    );
};
export default DayChart;
