import logo from "./logo.svg";
import styles from "./index.module.scss";
import { Button, Steps } from "antd-mobile";

const { Step } = Steps;

export default () => {
    return (
        <div className={styles.steps}>
        </div>
    );
};
