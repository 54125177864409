import React, { useEffect, useState } from "react";
import { Card } from "antd-mobile";
import * as echarts from "echarts";
import styles from "./index.module.scss";
import { HOST, HOST_OES, SITE_SOURCE } from "../../../../utils/consts";
import OCLImg from "../../../../assets/ocl-callback.png";
import OEImg from "../../../../assets/oe-callback.png";
import axios from "axios";
import BizIcon from "@/components/bizIcon";
import { background } from "@/utils/background";

const Offline = (props) => {
    const { title } = props;
    return (
        <div className={styles.container} style={background()}>
            <div className={styles.title}>{title}</div>
            <BizIcon type="offline" className={styles.icon} />
            <div className={styles.desc}>设备未接入网络</div>
            {/* 已删除 */}
            {/* <div className={styles.card}>
                <p>原因排查：</p>

                <p>1. 请确保设备使用的SIM卡是否欠费，如果已欠费请尽快进行充值；</p>

                <p>2. 请联系技师确认IoT网关是否已安装并激活。</p>

                <p>如果仍未解决您的问题，请拨打客服热线 ：</p>
                <a  href={"tel:" + (SITE_SOURCE === "OCL" ? "400-818-5588" : "400-885-0000")} className={styles.tel} onClick={() => onDial()}>
                    {SITE_SOURCE === "OCL" ? "400-818-5588" : "400-885-0000"}
                </a>
            </div> */}
        </div>
    );
};

export default Offline;
