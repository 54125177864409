import React from "react";
import { SITE_SOURCE } from "../../utils/consts.js";

const Mark = (props) => {
  const { fontSize, top } = props;
  if (SITE_SOURCE == "OCL") {
    return (
      <span
        style={{
          position: "relative",
          fontSize: fontSize,
          top: top,
          margin: 0,
        }}
      >
        ®
      </span>
    );
  } else {
    return (
      <span
        style={{
          margin: 0,
        }}
      >
        ™
      </span>
    );
  }
};

export default Mark;
