import React, { useEffect, useState } from "react";
import { Card, Popover, Grid, Toast } from "antd-mobile";
import styles from "./index.module.scss";
import { HOST, HOST_OES, SITE_SOURCE } from "../../utils/consts";
import axios from "axios";
import DayChart from "./components/DayChart";
import WeekChart from "./components/WeekChart";
import BizIcon from "@/components/bizIcon";
import Offline from "./components/Offline";
import { background } from "@/utils/background";
import UnitInfo from "./components/UnitInfo";
import moment from "moment";
import { InformationCircleOutline } from "antd-mobile-icons";
import { useHistory } from "react-router-dom";
import MyResult from "@/components/MyResult";
import { Clickphone_unitdetail } from "@/utils/sensors";
//steps对应文案
const progress = {
    1: "技师正火速赶来",
    2: "技师正火速赶来",
    3: "技师已到达现场，正在修复",
    4: "技师已到达现场，正在修复",
    5: "维修技师已完成修复",
};

const appServer = {
    cx: "cxServer",
    h5: "h5",
};

const desc = (
    <>
        <p>暂无数据</p>
        <p>请确认IoT网关是否已安装并激活</p>
    </>
);
const decommissionedDesc = (
    <>
        <p>设备未激活</p>
        <p>请确认IoT网关是否已安装并激活</p>
    </>
);

export default () => {
    const location = useHistory().location;
    let _params = new URL(window.location).searchParams;

    const site_source = SITE_SOURCE === "OCL" ? "ocl-" : "oe-";

    const [DayData, setDayData] = useState(null);
    const [WeekData, setWeekData] = useState(null);
    const [unitData, setUnitData] = useState(null);
    const [params, setParams] = useState(null);
    const [isFault, setIsFault] = useState(null);
    const [uptimeStatistics, setUptimeStatistics] = useState(null);
    const [recentCallback, setRecentCallback] = useState(null);
    const [recentCompletedCallback, setRecentCompletedCallback] = useState(null);
    const [recentCompletedMaintenance, setRecentCompletedMaintenance] = useState(null);
    const [recentScheduledMaintenance, setRecentScheduledMaintenance] = useState(null);
    const popoverContent = (
        <>
            <p>楼层数值为系统最后接收到的楼层信息,</p>
            <p>可能与设备实际所在楼层有所偏差。</p>
        </>
    );

    const systemServer = appServer[_params.get("system").toLowerCase()] ?? "h5";

    let unitApi = {
        unitDetail: `/api/${systemServer}/unitDetail`,
        isFault: `/api/oes/${systemServer}/unit/isFault`,
        uptimeStatistics: `/api/oes/${systemServer}/unit/uptimeStatistics`,
        dailyRunCounts: `/api/${systemServer}/dailyRunCounts`,
        weeklyRunCounts: `/api/${systemServer}/weeklyRunCounts`,
        recentCallback: `/api/oes/${systemServer}/unit/recentCallback`,
        recentCompletedCallback: `/api/oes/${systemServer}/unit/recentCompletedCallback`,
        recentCompletedMaintenance: `/api/oes/${systemServer}/unit/recentCompletedMaintenance`,
        recentScheduledMaintenance: `/api/oes/${systemServer}/unit/recentScheduledMaintenance`,
    };

    //是否故障
    const fetchIsFault = async () => {
        const res = await axios.post(HOST_OES + unitApi.isFault, { ...params });
        setIsFault(res.data);
    };

    //上一次召修
    const fetchRecentCallback = async () => {
        const res = await axios.post(HOST_OES + unitApi.recentCallback, { ...params });
        setRecentCallback(res.data);
    };
    //最近一条已完成召修
    const fetchRecentCompletedCallback = async () => {
        const res = await axios.post(HOST_OES + unitApi.recentCompletedCallback, { ...params });
        setRecentCompletedCallback(res.data);
    };
    //上一次保养
    const fetchRecentCompletedMaintenance = async () => {
        const res = await axios.post(HOST_OES + unitApi.recentCompletedMaintenance, { ...params });
        setRecentCompletedMaintenance(res.data);
    };
    //最近计划的保养
    const fetchRecentScheduledMaintenance = async () => {
        const res = await axios.post(HOST_OES + unitApi.recentScheduledMaintenance, { ...params });
        setRecentScheduledMaintenance(res.data);
    };

    //最近7日健康运行效率
    const fetchUptimeStatistics = async () => {
        const res = await axios.post(HOST_OES + unitApi.uptimeStatistics, { ...params });
        setUptimeStatistics(res.data);
    };

    //获取昨日分时统计
    const fetchDayData = async () => {
        const res = await axios.post(HOST + unitApi.dailyRunCounts, { ...params });
        let getPeakTime = (peak) => {
            let _peak = {};
            if (peak) {
                if (peak.range.split("~")[0] === 0) {
                    _peak.start = 0;
                } else {
                    _peak.start = peak.range.split("~")[0] / 2;
                }
                _peak.value = peak.value;
                return _peak;
            } else return null;
        };
        let _DayData = {
            realxAxis: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
            fakexAxis: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
            runCounts: [...res.data.runCounts],
            PeakTime: {
                Morning: getPeakTime(res.data.morningPeak),
                Noon: getPeakTime(res.data.noonPeak),
                Evening: getPeakTime(res.data.eveningPeak),
            },
        };
        setDayData(_DayData);
    };

    //获取近7日流量统计
    const fetchWeekData = async () => {
        const res = await axios.post(HOST + unitApi.weeklyRunCounts, { ...params });
        let _WeekData = {
            data: res.data?.dateInfo?.map((x, index) => x.dateLabel + "|" + x.weekLabel),
            value:
                res.data?.runCounts.filter((v) => v !== "0" && v !== "-").length === 0
                    ? []
                    : res.data?.runCounts.map((x) => {
                          return parseFloat(x);
                      }),
        };
        setWeekData(_WeekData);
    };

    const getweek = (e = 1) => {
        var week = ["（周一）", "（周二）", "（周三）", "（周四）", "（周五）", "（周六）", "（周日）"];
        return week[e - 1];
    };

    const showMyResult = () => {
        if (_params.get("system").toLowerCase() == "cx") {
            //处理CX登录已失效的提示
            if (unitData?.message == "402002") {
                return <MyResult icon="empty" desc={<p>当前链接已失效</p>} />;
            }
            if (unitData?.message == "402003") {
                return <MyResult icon="empty" desc={<p>签名错误</p>} />;
            }
        } else {
            //设备decommissioned的展示
            if (unitData?.commissioningStatus == "decommissioned") {
                return <MyResult icon="empty" desc={decommissionedDesc} />;
            }
            //如果系统未查询到（CX传过来的）相关设备ID，则跳转至提示页 ;
            if (unitData?.errorCode == "error.units.unit_not_exist") {
                return <MyResult icon="empty" desc={desc} />;
            }
        }
    };

    const fetchAll = () => {
        return new Promise((resolve, reject) => {
            //获取设备信息
            axios.post(HOST + unitApi.unitDetail, { ...params }).then((res) => {
                Toast.clear();
                //decommissioned 设备未激活无需继续请求
                if (res.data.commissioningStatus == "decommissioned") {
                    setUnitData(res.data);
                    return;
                } else {
                    setUnitData(res.data);
                    resolve();
                }
            });
        }).then(() => {
            fetchDayData();
            fetchWeekData();
            fetchIsFault();
            fetchUptimeStatistics();
            fetchRecentCallback();
            fetchRecentCompletedCallback();
            fetchRecentCompletedMaintenance();
            fetchRecentScheduledMaintenance();
        });
    };

    const getQueryString = (name) => {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return decodeURIComponent(r[2]);
        }
        return null;
    };

    //处理三方跳转
    useEffect(() => {
        setParams({
            userid: _params.get("userid"),
            unitNumber: _params.get("unitNumber"),
            sign: getQueryString("sign"),
            timestamp: _params.get("timestamp"),
            system: _params.get("system").toUpperCase() ?? "h5",
            entity: _params.get("entity") ? _params.get("entity").toUpperCase() : SITE_SOURCE,
        });
    }, [window.location]);

    //处理H5召修跳转
    useEffect(() => {
        if (location.state) {
            setParams({
                unitNumber: location.state.unitNumber,
                system: location.state.system,
            });
        }
    }, [location]);

    useEffect(() => {
        if (params) {
            Toast.show({
                icon: "loading",
                content: "加载中…",
            });
            fetchAll();
        }
    }, [params]);

    return (
        <React.Fragment>
            {showMyResult() ? (
                showMyResult()
            ) : (
                <>
                    <>
                        {unitData && !unitData?.online ? (
                            //离线展示
                            <Offline title={unitData?.aliasName} />
                        ) : (
                            <>
                                {unitData && (
                                    <div className={styles.container} style={background()}>
                                        <UnitInfo
                                            unitData={unitData}
                                            uptimeStatistics={uptimeStatistics}
                                            isFault={isFault}
                                        />
                                        {/* 召修展示  0未开始，1未完成（保存过） */}
                                        {recentCallback &&
                                            !(
                                                recentCallback?.callbackStatus == -1 ||
                                                recentCallback?.callbackStatus == 7 ||
                                                recentCallback?.callbackStatus == 8 ||
                                                recentCallback?.callbackStatus == -2
                                            ) && (
                                                <div className={styles.card}>
                                                    <div className={styles.user}>
                                                        <div className={styles.avatar} />
                                                        <div className={styles.info2}>
                                                            <div className={styles.basic}>
                                                                <Popover content="123" placement="top">
                                                                    <span className={styles.name}>
                                                                        {recentCallback?.mechanic}
                                                                    </span>
                                                                </Popover>
                                                                <BizIcon
                                                                    type="temperature"
                                                                    className={styles["health-icon"]}
                                                                />
                                                                <span className={styles.health}>体温正常</span>
                                                            </div>
                                                            <div className={styles.status}>
                                                                {progress[recentCallback?.progressKey]}
                                                            </div>
                                                        </div>
                                                        <a
                                                            href={"tel:" + recentCallback?.mechanicMobile}
                                                            onClick={() => {
                                                                Clickphone_unitdetail({
                                                                    unitNumber: unitData?.unitNumber,
                                                                    buildingName: unitData?.buildingName,
                                                                    unitCondition: unitData?.online
                                                                        ? "设备在线"
                                                                        : "未接入网络",
                                                                    unitstatus: isFault ? "故障召修" : "运行中",
                                                                    phoneType: "技师电话",
                                                                    mechanicName: recentCallback?.mechanic,
                                                                });
                                                            }}
                                                        >
                                                            <BizIcon
                                                                type={site_source + "mobile"}
                                                                className={styles["call"]}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            )}

                                        {/* 电梯详情 */}
                                        <div className={styles.info}>
                                            <div className={styles.item}>
                                                {unitData?.unitType == "Escalator" ? (
                                                    <>
                                                        <span>运行状态</span>
                                                        <br />
                                                        <span>
                                                            {unitData?.direction == 0 && "静止"}
                                                            {unitData?.direction == 1 && "扶梯上行"}
                                                            {unitData?.direction == 2 && "扶梯下行"}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>当前楼层</span>
                                                        <Popover
                                                            content={popoverContent}
                                                            placement="rightTop"
                                                            style={{ top: 300 }}
                                                        >
                                                            <InformationCircleOutline className={styles.required} />
                                                        </Popover>

                                                        <br />
                                                        <span>{unitData && parseInt(unitData?.dispFloor)}</span>
                                                    </>
                                                )}
                                            </div>
                                            <div className={styles.item}>
                                                <span>梯龄</span>
                                                <br />
                                                <span>{unitData?.unitAge < 1 ? "<1" : unitData?.unitAge}年</span>
                                            </div>
                                            <div className={styles.item}>
                                                <span>上一次召修</span>
                                                <br />
                                                <span>
                                                    {recentCompletedCallback?.contactAttemptedTime
                                                        ? moment(recentCompletedCallback?.contactAttemptedTime).format(
                                                              "YYYY/MM/DD"
                                                          )
                                                        : "-"}
                                                </span>
                                            </div>
                                            <div className={styles.item}>
                                                <span>上一次保养</span>
                                                <br />
                                                <span>
                                                    {recentCompletedMaintenance?.itemDate == null
                                                        ? "-"
                                                        : moment(recentCompletedMaintenance.itemDate).format(
                                                              "YYYY/MM/DD"
                                                          )}
                                                </span>
                                            </div>
                                        </div>
                                        {recentScheduledMaintenance?.checkDate ? (
                                            <div className={styles.maintain}>
                                                设备将于
                                                {moment(recentScheduledMaintenance?.checkDate).format("YYYY/MM/DD")}
                                                {getweek(moment().format("E"))}进行下一次保养
                                            </div>
                                        ) : (
                                            <div className={styles.unmaintained}>
                                                未获取到下一次保养信息，请确认OTIS维保服务是否已到期
                                            </div>
                                        )}

                                        <Card title="昨日分时统计" className={styles.mt16}>
                                            <DayChart DayData={DayData} unitType={unitData?.unitType} />
                                        </Card>

                                        <Card title="近7日流量统计" className={styles.mt16}>
                                            <WeekChart WeekData={WeekData} unitType={unitData?.unitType} />
                                        </Card>

                                        {/* 服务 */}
                                        <div className={styles.service}>
                                            <div className={styles.header}>
                                                <BizIcon type="service" className={styles.icon} />
                                                <span>您正在享受以下服务</span>
                                            </div>
                                            <Grid columns={3} gap={8}>
                                                <Grid.Item>
                                                    <div className={styles.item}>
                                                        <BizIcon type="IOT" className={styles.icon} />
                                                        <p>IoT助手</p>
                                                        <p>设备信息一目了然</p>
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item>
                                                    <div
                                                        className={
                                                            unitData?.currentStatus == "Active"
                                                                ? styles.item
                                                                : styles.item_disabled
                                                        }
                                                    >
                                                        <BizIcon type="lingjian" className={styles.icon} />
                                                        <p>原厂零件</p>
                                                        <p>优先使用原厂零件</p>
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item>
                                                    <div
                                                        className={
                                                            unitData?.currentStatus == "Active"
                                                                ? styles.item
                                                                : styles.item_disabled
                                                        }
                                                    >
                                                        <BizIcon type="weibao" className={styles.icon} />
                                                        <p>维修与保养服务</p>
                                                        <p>让设备经久耐用</p>
                                                    </div>
                                                </Grid.Item>
                                                {/* 如果该设备由OTIS维保且处于维保期，且CBM版本为3.0，则“智慧保养”(OE为"物联网Plus) icon高亮，反之则不显示 */}
                                                {unitData?.currentStatus == "Active" && unitData?.cbm == "3.0" && (
                                                    <Grid.Item>
                                                        <div
                                                            className={
                                                                unitData?.currentStatus == "Active" &&
                                                                unitData?.cbm == "3.0"
                                                                    ? styles.item
                                                                    : styles.item_disabled
                                                            }
                                                        >
                                                            <BizIcon type="zhihui" className={styles.icon} />
                                                            <p>{SITE_SOURCE === "OCL" ? "智慧保养" : "物联网Plus"}</p>
                                                            <p>24小时远程守护电梯</p>
                                                        </div>
                                                    </Grid.Item>
                                                )}

                                                {/* <Grid.Item>
                                <div className={styles.item}>
                                    <BizIcon type="regen" className={styles.icon} />
                                    <p>ReGen省电技术</p>
                                    <p>省电更省心</p>
                                </div>
                            </Grid.Item> */}
                                            </Grid>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                </>
            )}
        </React.Fragment>
    );
};
