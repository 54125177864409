import Home from "./home";
import Fix from "./fix/index.jsx";
import Maintain from "./maintain/index.jsx";
import Login from "./login";
import Nav from "./nav";
import login from "./login";
import viewpager from "./viewpager/index.jsx";
import unit from "./unit";
import poster from "./poster";
import guide from "./guide";

export default [
    {
        route: "/",
        exact: true,
        component: Fix,
        title: "召修详情",
    },
    {
        route: "/fix",
        exact: true,
        component: Fix,
        title: "召修详情",
    },
    {
        route: "/unit",
        exact: true,
        component: unit,
        title: "设备详情",
    },
    {
        route: "/viewpager",
        exact: true,
        component: viewpager,
        title: "保养详情",
    },
    {
        route: "/poster",
        exact: true,
        component: poster,
        title: "海报",
    },
    {
        route: "/guide",
        exact: true,
        component: guide,
        title: "新手指引",
    },
    // {
    //     route: "/home",
    //     exact: true,
    //     component: Home,
    //     title: "主页",
    // },

    // {
    //     route: "/maintain",
    //     exact: true,
    //     component: Maintain,
    //     title: "保养详情",
    // },

    // {
    //     route: "/nav",
    //     exact: true,
    //     component: Nav,
    //     title: "跳转页面",
    // },
    // {
    //     route: "/login",
    //     exact: true,
    //     component: Login,
    //     title: "登录",
    // },
];
