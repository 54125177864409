import React from "react";
import BizIcon from "@/components/bizIcon";
import styles from "./index.module.scss";
import { background } from "@/utils/background";

const MyResult = (props) => {
    const { icon, title, desc } = props;
    return (
        <div className={styles.container} style={background()}>
            <BizIcon type={icon} className={styles.iconClass} />
            <div className={styles.title}>{title}</div>
            <div className={styles.desc}>{desc}</div>
        </div>
    );
};
export default MyResult