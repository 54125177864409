import React, { useEffect, useState } from "react";

import * as echarts from "echarts";

const WeekChart = (props) => {
    const { WeekData, unitType } = props;
    let myChart;
    const xyAxis = {
        xAxis: [
            {
                name: "日期",
                nameLocation: "end",
                nameGap: 20,
                nameTextStyle: {
                    align: "right",
                    verticalAlign: "top",
                    lineHeight: 30,
                    color: "#ccc",
                },
                show: true,
                type: "category",
                data: WeekData?.data,
                axisLine: {
                    show: true,
                    symbol: ["none", "triangle"],
                    symbolSize: [5, 10],
                    lineStyle: {
                        width: 1,
                        color: "#cfcfcd",
                    },
                },
                axisLabel: {
                    interval: 0,
                    formatter: (value) => {
                        return "{date|" + value.split("|")[0] + "}\n{day|" + value.split("|")[1] + "}";
                    },
                    rich: {
                        date: {
                            color: "#000",
                            fontSize: 11,
                            // fontWeight: "bold",
                        },
                        day: {
                            fontSize: 11,
                            lineHeight: 24,
                            color: "#CFCFCD",
                        },
                    },
                },
                //隐藏x轴刻度线
                axisTick: {
                    show: false,
                },
            },
        ],
        grid: {
            left: "15%",
        },
        yAxis: [
            {
                type: "value",
                name: unitType == "Elevator" ? "次数" : "人次",
                offset: 1,
                boundaryGap: [0, "5%"],
                nameTextStyle: {
                    align: "right",
                    color: "#ccc",
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: "#F2F2F2",
                    },
                },
                axisLine: {
                    show: true,
                    symbol: ["none", "triangle"],
                    symbolSize: [5, 10],
                    lineStyle: {
                        width: 1,
                        color: "#cfcfcd",
                    },
                },
                //不显示y轴上的刻度0
                axisLabel: {
                    color: "#000",
                    formatter: (value) => {
                        if (value != 0) {
                            return value;
                        }
                    },
                },
            },
        ],
    };

    // const [date, setDate] = useState(WeekData.data);

    useEffect(() => {

        if (WeekData) {
            if (!myChart) {
                myChart = echarts.init(document.getElementById("chart-week"));
                myChart.showLoading({
                    text: "正在加载...",
                });
            }
            myChart.hideLoading();
            if (WeekData.value) {
                if (WeekData.value.length > 0) {
                    let option = {
                        title: {
                            show: false,
                        },
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            borderWidth: 0,
                            axisPointer: {
                                type: "shadow",
                            },
                            position: (point, params, dom, rect, size) => {
                                return [point[0] - size.contentSize[0] / 2, point[1] - size.contentSize[1] - 10];
                            },
                            formatter: (params) => {
                                const { name, data } = params[0];
                                let _name = name.split("|").join(" ");
                                return `${_name}<br>设备流量：<strong style="font-size:16px">${
                                    data.value ? data.value : isNaN(data) ? "-" : data
                                }</strong> ${unitType == "Elevator" ? "次" : "人次"}`;
                            },
                        },
                        series: [
                            {
                                type: "bar",
                                itemStyle: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: "#00a0e9",
                                        },
                                        {
                                            offset: 0.34,
                                            color: "#0A98DA",
                                        },
                                        {
                                            offset: 0.54,
                                            color: "#1193D0",
                                        },
                                        {
                                            offset: 1,
                                            color: "#2585B5",
                                        },
                                    ]),
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            {
                                                offset: 0,
                                                color: "#003E80",
                                            },
                                            {
                                                offset: 1,
                                                color: "#00A0E9",
                                            },
                                        ]),
                                    },
                                },
                                data: [
                                    WeekData.value[0],
                                    WeekData.value[1],
                                    WeekData.value[2],
                                    WeekData.value[3],
                                    WeekData.value[4],
                                    WeekData.value[5],
                                    WeekData.value[6],
                                ],
                                barWidth: 26,
                            },
                        ],
                        ...xyAxis,
                    };
                    myChart.setOption(option);
                }else {
                    let option = {
                        title: {
                            show: true,
                            text: "暂无数据",
                            x: "center",
                            y: "center",
                            textStyle: {
                                color: "#65ABE7",
                                fontWeight: "normal",
                                fontSize: 14,
                            },
                        },
                        series: [
                            {
                                type: "bar",
                                data: [],
                            },
                        ],
                        ...xyAxis,
                    };
                    myChart.setOption(option);
                }
            } else {
                let option = {
                    title: {
                        show: true,
                        text: "暂无数据",
                        x: "center",
                        y: "center",
                        textStyle: {
                            color: "#65ABE7",
                            fontWeight: "normal",
                            fontSize: 14,
                        },
                    },
                    series: [
                        {
                            type: "bar",
                            data: [],
                        },
                    ],
                    ...xyAxis,
                };
                myChart.setOption(option);
            }
        }
    }, [WeekData]);

    return (
        <div
            id="chart-week"
            style={{
                width: "auto",
                height: "255px",
                background: "#fff",
            }}
        ></div>
    );
};
export default WeekChart;
