import React, { useEffect, useState } from "react";
import { Card } from "antd-mobile";
import * as echarts from "echarts";
import styles from "./index.module.scss";
import { HOST, HOST_OES, SITE_SOURCE } from "../../../../utils/consts";
import OCLImg from "../../../../assets/ocl-callback.png";
import OEImg from "../../../../assets/oe-callback.png";
import axios from "axios";
import BizIcon from "@/components/bizIcon";
import { background } from "@/utils/background";

const UnitInfo = (props) => {
    const { unitData, uptimeStatistics, isFault } = props;

    const handleRating = (data = 0) => {
        let _data = data * 100;
        let text = "",
            bg = "";
        if (_data >= 99) {
            text = "优秀";
            bg = "#00C474";
        }
        if (_data >= 90 && _data < 99) {
            text = "良好";
            bg = "#FF722D";
        }
        if (_data < 90) {
            text = "一般";
            bg = "#FF2A57";
        }
        return { text, bg };
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>{unitData?.aliasName || '-'}</div>
            <div className={styles.efficiency}>
                <div className={styles.value}>
                    {uptimeStatistics?.uptime * 100 || 0}
                    <div className={styles.item}>
                        <div
                            className={styles.tag}
                            style={{ backgroundColor: handleRating(uptimeStatistics?.uptime).bg }}
                        >
                            {handleRating(uptimeStatistics?.uptime).text}
                        </div>
                        <div className={styles.unit}>%</div>
                    </div>
                </div>
            </div>
            <p>过去7天健康运行效率</p>
            <div className={styles.state}>
                <div className={styles.item}>
                    <BizIcon type="online" className={styles.icon} />
                    <span className={styles.label}>设备在线</span>
                </div>
                <div className={styles.item}>
                    <BizIcon type={isFault ? "guzhang" : "running"} className={styles.icon} />
                    <span className={styles.label}>{isFault ? "故障召修" : "运行中"}</span>
                </div>
            </div>
        </div>
    );
};
export default UnitInfo;
