import { Toast } from "antd-mobile";
import errorConfig from "./error.config";
const { REACT_APP_ENV } = process.env;
const verifyApp = () => {
    let host = location.host.toLowerCase();
    return host.indexOf("otisconnect") >= 0 || host.indexOf("cpoe") >= 0 ? "OE" : "OCL";

    // return /otisconnect|cpocl/.test(location.host.toLowerCase()) ? "OE" : "OCL";
};
const apis = {
    dev_host: "https://spring-otisone-cn-dev-cp-backend.azuremicroservices.io",
    test_host: "https://spring-otisone-cn-stag-cp-backend.azuremicroservices.io",
    production_host: "https://spring-otisone-cn-prod-cp-backend.azuremicroservices.io",

    dev_host_oes: "https://spring-otisone-cn-prod-cp-oes-dev.azuremicroservices.io",
    test_host_oes: "https://spring-otisone-cn-prod-cp-oes-stag.azuremicroservices.io",
    production_host_oes: "https://spring-otisone-cn-prod-cp-oes.azuremicroservices.io",
};

// 跳转PC端CP的链接地址
const pcOrigin = {
    ocl_dev: "https://customer-portal-dev.otisone.cn",
    ocl_test: "https://customer-portal-stag.otisone.cn",
    ocl_production: "https://www.otisone.cn",

    oe_dev: "https://customer-portal-dev.otisconnect.cn",
    oe_test: "https://customer-portal-stag.otisconnect.cn",
    oe_production: "https://www.otisconnect.cn",
};

const SITE_SOURCE = verifyApp();
const HOST = apis[`${REACT_APP_ENV}_host`.toLowerCase()];
const HOST_OES = apis[`${REACT_APP_ENV}_host_oes`.toLowerCase()];
const PC_HOST = pcOrigin[`${SITE_SOURCE}_${REACT_APP_ENV}`.toLowerCase()];

// 海报相关
let SAS_TOKEN, CDN_HOST;
switch (REACT_APP_ENV) {
    case "dev":
        SAS_TOKEN =
        "sv=2023-01-03&ss=btqf&srt=sco&spr=https&st=2023-11-20T08%3A12%3A12Z&se=2026-11-19T08%3A01%3A40Z&sp=rw&sig=Cqx4qOZSDjiDJLjjHAGyx7O%2FWQmpPm%2FXb9oYgOGIojY%3D";
        CDN_HOST = "https://eview-dev.azureedge.net";
        break;
    case "test":
        SAS_TOKEN =
        "sv=2023-01-03&ss=btqf&srt=sco&spr=https&st=2023-11-20T08%3A04%3A23Z&se=2026-11-19T08%3A01%3A40Z&sp=rw&sig=PtLeZSKC3jFgo5RfluJHISCYUHwN3mycJ7c09RQ%2Fn78%3D";
        CDN_HOST = "https://eview-stag.azureedge.net";
        break;
    case "production":
        SAS_TOKEN =
            "sv=2023-01-03&ss=btqf&srt=sco&spr=https&st=2023-11-20T08%3A14%3A57Z&se=2026-11-19T08%3A01%3A40Z&sp=rw&sig=05mqjNc8V%2FY0Jb7YME7m5hyTdchvKWKn7SNCITHs8Ys%3D";
        CDN_HOST = "https://eview-prod.azureedge.net";
        break;
    default:
        break;
}

if (!SITE_SOURCE || !HOST || !HOST_OES || !PC_HOST) {
    console.log(SITE_SOURCE, HOST, HOST_OES, PC_HOST);
    Toast.show({
        icon: "fail",
        content: errorConfig[400001],
        duration: 3000,
    });
}

export { HOST, HOST_OES, SITE_SOURCE, PC_HOST, REACT_APP_ENV, SAS_TOKEN, CDN_HOST };
