import { SITE_SOURCE, REACT_APP_ENV } from "@/utils/consts";
import moment from "moment"
/**
 * 神策track
 * name: 事件名称
 * payload: 提交的内容
 */
const track = (name, payload) => {
    // 神策
    const sensors = window["sensorsDataAnalytic201505"];
    sensors.registerPage({
        otis_env: REACT_APP_ENV,
        otis_source: SITE_SOURCE,
    });
    try {
        // return console.log("神策SDk", payload)
        sensors.track(name, payload);
    } catch (err) {
        console.error(err);
    }
};
/**
 * 获取页面标题
 */
const getTitle = () => document.title;
// 41 召修详情页拨打电话
export const Clickphone_callback = (payload) => {
    let _payload = {
        ...payload,
    };
    track("Clickphone_callback", _payload);
};
// 42 x
export const viewAllcallback = (payload) => {
    let _payload = {
        ...payload,
    };
    track("viewAllcallback", _payload);
};
// 43 x
export const ClickCopyURL = (payload) => {
    let _payload = {
        ...payload,
    };
    track("ClickCopyURL", _payload);
};
// 44 x
export const Clickphone_unitdetail = (payload) => {
    let _payload = {
        ...payload,
    };
    track("Clickphone_unitdetail", _payload);
};

export const message_click = (payload) => {
    let _payload = {
        ...payload,
        messageTime: payload.messageTime ? moment(payload.messageTime).format("YYYY/MM/DD") : "",
        push_mode: payload?.push_mode || "网站",
        message_type: payload?.message_type || MessageTypeToText(payload.messageType_id),
    };
    track("message_click", _payload);
};

function MessageTypeToText(id) {
    let types = {
        T1: "修改失败",
        T2: "修改成功",
        T3: "新建账号",
        T4: "修改设备",
        T5: "修改信息",
        T6: "审批提醒",
        T7: "用户删除",
        T8: "申请通过",
        T9: "申请失败",
        T10: "审批提醒",
        T11: "账号删除",
        T12: "取消管理",
        T13: "新增管理",
        T14: "故障召修",
        T15: "召修关闭",
        T16: "审批提醒",
        T17: "故障召修",
        T18: "召修关闭",
        T19: "账号申请",
        T20: "审批提醒",
        T21: "故障召修",
        T22: "召修关闭",
        T23: "年检提醒",
        T24: "保养计划",
        T25: "故障召修",
        T26: "召修关闭",
        T27: "年检提醒",
        T28: "保养计划",
        T29: "新的周报",
        T30: "新的月报",
        T31: "试用到期",
        T32: "新的周报",
        T33: "新的月报",
        T34: "新的周报",
        T35: "新的月报",
        T36: "新的周报",
        T37: "新的月报",
        T38: "账号创建成功",
        T39: "手机号登录",
        T40: "设置密码",
        T41: "绑定/修改手机号",
        T42: "修改邮箱",
        T43: "到期提醒",
        T44: "设备变化",
        T45: "设备绑定",
        T46: "账号申请",
        T47: "审批提醒",
        T48: "召修取消",
        T49: "设备名称修改",
        T50: "召修取消",
        T51: "召修取消",
        T52: "设备名称修改",
        T53: "邀请注册",
        T54: "绑定设备通知",
        T55: "驳回注册申请",
        T56: "召修取消",
    };
    return types[id];
}
export function Outside(location) {
    let { _p, _t } = location;
    message_click({
        push_mode: _p === "S" ? "短信" : _p === "E" ? "邮件" : "网站",
        message_type: MessageTypeToText(_t),
        messageType_id: _t,
        message_url: `${window.location.origin}${window.location.pathname}`,
    });
}
