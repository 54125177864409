import React, { useEffect, useState } from "react";
import { background } from "@/utils/background";
import { Toast } from "antd-mobile";

import qs from "query-string";
import styles from "./index.module.scss";
import { SAS_TOKEN, CDN_HOST } from "../../utils/consts";

export default function Poster() {
    const [imgLoadedFlag, setImgLoadedFlag] = useState(0);
    const [imgs, setImgs] = useState([]);
    useEffect(() => {
        if (imgs.length !== imgLoadedFlag) {
            Toast.show({
                icon: "loading",
                content: "加载中…",
            });
        } else {
            Toast.clear();
        }
    }, [imgLoadedFlag]);
    useEffect(() => {
        const { query } = qs.parseUrl(window.location.href);
        const imgs = [];
        for (const key in query) {
            if (Object.hasOwnProperty.call(query, key)) {
                const imgSrc = `${CDN_HOST}/eview/poster/${query[key]}.png?${SAS_TOKEN}`;
                imgs.push(imgSrc);
            }
        }
        setImgs(imgs);
    }, []);

    return (
        <React.Fragment>
            <div
                className={`${styles.container} ${imgs.length !== imgLoadedFlag ? styles.hidden : ""}`}
                style={background()}
            >
                {imgs.map((imgUrl, index) => (
                    <React.Fragment key={imgUrl}>
                        <div className={`${index !== 0 ? styles.hidden : ""}`}>
                            <p className={styles.h1}>你做的电梯保养海报出炉啦~</p>
                            <p className={styles.h2}>长按图片，赶紧保存或转发吧！</p>
                        </div>
                        <img
                            src={imgUrl}
                            style={{ maxWidth: "100%" }}
                            onLoad={() => {
                                // debugger;
                                setImgLoadedFlag(imgLoadedFlag + 1);
                            }}
                        />
                        <div className={styles.footer}>
                            第{index + 1}张 / 共 {imgs.length}张
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    );
}
