import React, { useEffect, useState } from "react";

import { ProFormCaptcha, ProFormText } from "@ant-design/pro-form";
import styles from "../index.module.scss";
import { MobileIcon, VcodeIcon } from "@/components/Icons";
import { Typography } from "antd";
const { Link } = Typography;

const MsgWithLinkToLogin = (content) => (
  <>
    {content}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <Link href="/user/login">返回登录&gt;</Link>
  </>
);

const MobileField = (props) => {
  const { submitType, dispatch, setDisabled, initialValues } = props;

  const [send, setSend] = useState(true);
  const [isCounting, setIsCounting] = useState(false);
  const checkPhone = (_, value) => {
    const promise = Promise;
    if (!/^1[3456789]\d{9}$/.test(value)) {
      if (!isCounting) setSend(true);
      if (setDisabled) setDisabled(true);
      return promise.reject("请输入正确的手机号。");
    } else {
      if (!isCounting) setSend(false);
      if (setDisabled) setDisabled(false);
      return promise.resolve();
    }
  };
  const countDown = 60;
  useEffect(() => {
    if (initialValues?.phone) {
      console.log("???", !/^1[3456789]\d{9}$/.test(initialValues?.phone));
      if (/^1[3456789]\d{9}$/.test(initialValues?.phone)) {
        if (!isCounting) setSend(false);
      }
    }
  }, [initialValues]);
  return (
    <div>
      <ProFormText
        fieldProps={{
          size: "large",
          prefix: <MobileIcon />,
          type: "number",
          pattern: "[0-9]*",
        }}
        name="phone"
        placeholder={"请输入手机号"}
        rules={[{ validator: checkPhone }]}
      />
      <ProFormCaptcha
        fieldProps={{
          size: "large",
          prefix: <VcodeIcon className={styles.prefixIcon} />,
          type: "number",
          pattern: "[0-9]*",
        }}
        captchaProps={{
          style: {
            height: "40px",
          },
          className: "btnCaptha",
          disabled: send,
          type: "primary",
        }}
        phoneName="phone"
        placeholder={"请输入验证码"}
        captchaTextRender={(timing, count) => {
          if (timing) {
            return `${count}s`;
          }

          return "获取验证码";
        }}
        countDown={countDown}
        name="smscode"
        rules={[
          {
            required: true,
            message: "请输入验证码。",
          },
        ]}
        onGetCaptcha={async (phone) => {
          // const result = await SendSmsCode({ phone, type: submitType });
          let { status } = result;
          // 神策 点击获取验证码
          if (status === 200) {
            dispatch({
              type: "login/setLoginState",
              payload: {
                success: "initial",
                errorCode: "",
              },
            });
            setSend(true);
            setIsCounting(true);
            await setTimeout(() => {
              if (send) {
                setSend(false);
                setIsCounting(false);
              }
            }, countDown * 1000);
          } else {
            let _payload = {
              submitting: false,
            };
            _payload.success = "false";
            _payload.errorCode = result.data.errorCode;
            if (status === 400) {
              if (
                result.data.errorCode === "error.reset.first_login_by_phone"
              ) {
                _payload.message = MsgWithLinkToLogin(result.message);
              } else if (
                result.data.errorCode === "error.users.phone_is_empty"
              ) {
                _payload.message = "";
              } else {
                _payload.message = result.message;
              }
            } else _payload.message = "发送验证码失败。";
            dispatch({
              type: "login/setLoginState",
              payload: _payload,
            });
            throw new Error(result.message);
          }
        }}
      />
    </div>
  );
};

export default MobileField;
