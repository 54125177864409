import logo from "./logo.svg";
import styles from "./index.module.scss";
import { Button, Steps } from "antd-mobile";
import { useEffect, useState } from "react";

const { Step } = Steps;

export default () => {
    const [data, setData] = useState({});
    useEffect(() => {
        const data = {
            name: "张三林",
            health: "体温正常",
            status: "维修技师已完成修复",
            result: "控制柜变频机死机，复位后正常",
            unit: {
                unitId: "RTX129312",
                age: 7,
                speed: 1.5,
                carry: 13500,
            },
        };

        setData(data);
    }, []);

    return (
        <div className={styles.steps}>
            <div className={styles.card}>
                <div className={styles.user}>
                    <img src="" />
                    <div className={styles.info}>
                        <div className={styles.basic}>
                            <span>{data.name}</span>
                            <span className={styles.health}>{data.health}</span>
                        </div>
                        <div className={styles.status}>{data.status}</div>
                    </div>
                    {/* <CheckCircleFill /> */}
                </div>
                <div className={styles.result}>{`诊断结果：${data.result}`}</div>
            </div>
            <div className={styles.unit}>
                <div>
                    <span className={styles.id}>{data.unit?.id}</span>
                    <span>故障设备编号</span>
                </div>
                <div className={styles.detail}>
                    <UnitDetailItem label="梯龄">{data.unit.age}</UnitDetailItem>
                </div>
            </div>
            <button>测试</button>
        </div>
    );
};

function UnitDetailItem({ label, value, children }) {
    return (
        <div className={styles["detail-item"]}>
            <span>{label}</span>
            {children}
        </div>
    );
}
