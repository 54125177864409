import React, { useEffect, useState } from "react";
import styles from "./Empty.module.scss";
import BizIcon from "@/components/bizIcon";
import OCLImg from "@/assets/ocl-callback.png";
import OEImg from "@/assets/oe-callback.png";
import { SITE_SOURCE } from "@/utils/consts";

function Empty(props) {
  return (
    <div className={styles.mode} style={SITE_SOURCE === "OCL" ? { backgroundImage: `url(${OCLImg})` } : { backgroundImage: `url(${OEImg})` }}>
      {
        !props.loading && <>
          <BizIcon type={SITE_SOURCE === "OCL" ? "zhaoxiudanquxiao" : "zhaoxiudanquxiao-copy"} className={styles.icon} />
          <p className={styles.text}>{props.text}</p>
        </>
      }
    </div>
  );
}

export default Empty;