import { Alert, Tabs, Layout, Checkbox, Typography, Modal, Space, Button } from "antd";
import ProForm, { ProFormText } from "@ant-design/pro-form";
import { MailIcon, LockIcon } from "@/components/Icons";
import React, { useState, useEffect } from "react";
import MobileField from "./MobileField";
import styles from "../index.module.scss";
import { SITE_SOURCE } from "../../../utils/consts";
import Mark from "../../../components/mark";
// import jwt_decode from "jwt-decode";

const { Link } = Typography;
const { Footer } = Layout;
const { TabPane } = Tabs;

const LoginField = (props) => {
    const {
        handleLogin,
        handleTabs,
        submitting,
        submitting_sendsmscode,
        forgetPassword,
        success,
        message,
        type,
        formRef,
        errorCode,
        autoLogin,
        setAutoLogin,
        isTermsModalVisible,
        loginToPageType,
        loginToPagePayload,
        dispatch,
    } = props;
    const [checked, setCheck] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [modalChecked, setModalChecked] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    const LoginMessage = ({ content, type }) => {
        return (
            <Alert
                style={{
                    marginBottom: 24,
                }}
                closable
                message={content}
                type={type}
                showIcon
                onClose={() => {
                    dispatch({
                        type: "login/setLoginState",
                        payload: {
                            message: "",
                        },
                    });
                }}
            />
        );
    };

    const handleCancel = () => {
        dispatch({
            type: "login/setIsTermsModalVisible",
            payload: {
                isTermsModalVisible: false,
                loginToPagePayload: null,
            },
        });
    };

    const handleTerms = () => {
        dispatch({
            type: "login/login",
            payload: {
                ...loginToPagePayload,
                type: loginToPageType,
                privacyChecked: true,
            },
        });
        handleCancel();
    };

    useEffect(() => {
        if (!isTermsModalVisible) {
            setModalChecked(false);
        }
    }, [isTermsModalVisible]);

    // useEffect(() => {
    //   let last_token = localStorage.getItem("last_token");
    //   if (last_token) {
    //     let last_obj = JSON.parse(last_token);
    //     if (last_obj) {
    //       const { type, token } = last_obj;
    //       const decode = jwt_decode(token);
    //       if (type === "mobile") {
    //         setInitialValues({
    //           phone: decode.userInfo.phone,
    //         });
    //       }
    //       if (type === "email") {
    //         setInitialValues({
    //           email: decode.userInfo.email,
    //         });
    //       }
    //       setTimeout(() => {
    //         handleTabs(type);
    //       }, 100);
    //     }
    //   }
    // }, []);

    return (
        <div className="portal-form">
            <ProForm
                initialValues={{
                    ...initialValues,
                }}
                formRef={formRef}
                submitter={{
                    render: (_, dom) => dom.pop(),
                    submitButtonProps: {
                        loading: submitting,
                        size: "large",
                        style: {
                            width: "100%",
                        },
                        disabled: !checked,
                    },
                    searchConfig: {
                        submitText: "登录",
                    },
                }}
                onFinish={(values) => {
                    if (checked) {
                        handleLogin({ ...values, autoLogin, privacyChecked });
                        return Promise.resolve();
                    }
                }}
                className={styles.wrapper}
            >
                <Tabs activeKey={type} onChange={handleTabs}>
                    <TabPane tab="手机验证码登录" key="mobile" />
                    <TabPane tab="邮箱密码登录" key="email" />
                </Tabs>

                {success === "false" &&
                    message !== "" &&
                    type === "email" &&
                    !submitting &&
                    errorCode !== "error.reset.email_not_exists" && (
                        <LoginMessage
                            content={message}
                            type={errorCode === "error.auth.first_login_by_phone" ? "info" : "error"}
                        />
                    )}

                {type === "email" && (
                    <div>
                        <ProFormText
                            name="email"
                            fieldProps={{
                                size: "large",
                                prefix: <MailIcon />,
                            }}
                            placeholder={"请输入邮箱"}
                            rules={[
                                {
                                    required: true,
                                    message: "邮箱不能为空。",
                                },
                                {
                                    pattern: new RegExp(
                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ),
                                    message: "邮箱格式不正确。",
                                },
                            ]}
                        />
                        <ProFormText.Password
                            name="password"
                            fieldProps={{
                                size: "large",
                                prefix: <LockIcon />,
                                maxLength: 20,
                            }}
                            placeholder={"请输入密码"}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入密码。",
                                },
                            ]}
                        />
                    </div>
                )}
                {success === "false" &&
                    message !== "" &&
                    type === "mobile" &&
                    errorCode !== "error.users.phone_is_empty" && (
                        <>
                            <LoginMessage content={message} type="error" />
                        </>
                    )}
                {type === "mobile" && <MobileField submitType="login" initialValues={initialValues} />}

                <div
                    style={{
                        marginBottom: 10,
                        display: type === "email" ? "block" : "none",
                    }}
                >
                    <span className={styles.checkbox}>
                        <Checkbox
                            noStyle
                            name="autoLogin"
                            defaultChecked={true}
                            onChange={(e) => {
                                setAutoLogin(e.target.checked);
                            }}
                        >
                            自动登录
                        </Checkbox>
                    </span>

                    <a
                        style={{
                            float: "right",
                        }}
                        onClick={forgetPassword}
                    >
                        忘记密码
                    </a>
                </div>
            </ProForm>

            <Footer
                style={{
                    textAlign: "left",
                    color: "#A7A8A9",
                    background: "none",
                    marginBottom: 40,
                    fontSize: "12px",
                }}
            >
                <div>
                    <Checkbox checked={checked} onChange={() => setCheck(!checked)} />
                    <span style={{ paddingLeft: "4px" }}>已阅读并同意</span>
                    <Link
                        href="/OTIS_Elevator_Company_Terms_of_Use_and_Global_Privacy_Notice_for_IoT_web_portal.pdf"
                        target="_blank"
                        className={styles.linkbtn}
                    >
                        {/* {defaultSettings[SITE_SOURCE].title} */}
                        {SITE_SOURCE === "OCL" ? "Otis ONE" : "OTIS CONNECT"}
                        <Mark fontSize={"0.5vw"} top={"-0.15vw"} />
                        客户平台使用条款
                    </Link>
                </div>

                <div>
                    <Checkbox checked={privacyChecked} onChange={() => setPrivacyChecked(!privacyChecked)} />
                    <span style={{ paddingLeft: "4px" }}>已阅读并同意</span>
                    <Link
                        href="/OTIS_Elevator_Company_Terms_of_Use_and_Global_Privacy_Notice_for_IoT_web_portal_Privacy.pdf"
                        target="_blank"
                        className={styles.linkbtn}
                    >
                        {/* {defaultSettings[SITE_SOURCE].title} */}
                        {SITE_SOURCE === "OCL" ? "Otis ONE" : "OTIS CONNECT"}
                        <Mark fontSize={"0.5vw"} top={"-0.15vw"} />
                        客户平台隐私声明
                    </Link>
                </div>

                <div>
                    <Checkbox checked={privacyChecked} onChange={() => setPrivacyChecked(!privacyChecked)} />
                    <span style={{ paddingLeft: "4px" }}>已阅读并同意</span>
                    <Link
                        href="/OTIS_Elevator_Company_Terms_of_Use_and_Global_Privacy_Notice_for_IoT_web_portal_Privacy.pdf"
                        target="_blank"
                        className={styles.linkbtn}
                    >
                        {/* {defaultSettings[SITE_SOURCE].title} */}
                        {SITE_SOURCE === "OCL" ? "Otis ONE" : "OTIS CONNECT"}
                        <Mark fontSize={"0.5vw"} top={"-0.15vw"} />
                        客户平台隐私声明第2.4条
                    </Link>
                </div>
            </Footer>

            <Modal
                title="奥的斯电梯公司全球隐私声明"
                width="80%"
                visible={isTermsModalVisible}
                onCancel={handleCancel}
                centered={true}
                wrapClassName={styles.privacyModal}
                footer={
                    <div style={{ width: "100%", padding: "20px 0", textAlign: "center" }}>
                        <div style={{ paddingBottom: "16px" }}>
                            <Checkbox checked={modalChecked} onChange={() => setModalChecked(!modalChecked)} />
                            <span style={{ paddingLeft: "4px" }}>
                                已阅读并同意
                                {/* {defaultSettings[SITE_SOURCE].title} */}
                                {SITE_SOURCE === "OCL" ? "Otis ONE" : "OTIS CONNECT"}
                                <Mark fontSize={"0.5vw"} top={"-0.15vw"} />
                                客户平台隐私声明第2.4条
                            </span>
                        </div>
                        <div style={{ margin: "auto" }}>
                            <Button
                                className={styles.confirmBtn}
                                disabled={!modalChecked}
                                type="primary"
                                onClick={handleTerms}
                            >
                                已阅读并同意
                                {/* {defaultSettings[SITE_SOURCE].title} */}
                                {SITE_SOURCE === "OCL" ? "Otis ONE" : "OTIS CONNECT"}
                                <Mark fontSize={"0.5vw"} top={"-0.15vw"} />
                                客户平台隐私声明
                            </Button>
                        </div>
                    </div>
                }
            >
                <iframe
                    style={{ width: "100%", height: "400px", objectFit: "contain" }}
                    frameBorder="0"
                    src={`/OTIS_Elevator_Company_Terms_of_Use_and_Global_Privacy_Notice_for_IoT_web_portal_Privacy.pdf#toolbar=0&navpanes=0`}
                ></iframe>
            </Modal>
        </div>
    );
};

export default LoginField;
