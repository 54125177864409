import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import App from "./app";
import * as serviceWorkerRegistration from "./advanced/serviceWorkerRegistration";
import reportWebVitals from "./advanced/reportWebVitals";
import "./utils/axios.config";
import "./utils/sensorsdata.min";
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
